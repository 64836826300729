import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import BaseComponent from "../Helpers";
import { ReactComponent as CanceledIcon } from '../../../img/icon-canceled.svg';
import { ReactComponent as NewIcon } from '../../../img/icon-new.svg';
import { ReactComponent as ApprovedIcon } from '../../../img/icon-approved.svg';
import { ReactComponent as EndedIcon } from '../../../img/icon-ended.svg';
import { ReactComponent as ActiveIcon } from '../../../img/icon-active.svg';
import moment from 'moment';

class ReportItem extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = {
      active: false,
      photos: { expanded: false, list: [], error: false },
      loading: false
    };
  }

  getReceipts = async () => {
    const action = this.props.action;

    if (this.state.photos.list.length === 0) {
      this.setState({ ...this.state, loading: true })
    }

    const response = await fetch(
      process.env.REACT_APP_API_URL + "/action/receipt/list",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + JSON.parse(localStorage.getItem('state'))?.user?.token?.uuid
        },
        body: JSON.stringify({
          "action": {
            "uuid": action.uuid
          },
          "pagination": {
            "limit": 100,
            "offset": 0
          }
        })
      }
    );
    const receipts = await response.json();
    const imgs = []
    if (receipts.status.success) {
      for (const receipt of receipts.data.receipts) {
        imgs.push({
          img: await this.getPhoto(receipt.uuid),
          date: new Date(receipt.created.date).toLocaleTimeString('pl-PL', {
            hour: '2-digit',
            minute: '2-digit'
          })
        })
      }
    } else {
      this.setState({ ...this.state, photos: { ...this.state.photos, error: true } })
    }
    if (imgs.length === 0) {
      this.setState({ ...this.state, photos: { ...this.state.photos, list: "none" } })
    } else {
      this.setState({ ...this.state, photos: { ...this.state.photos, list: [...imgs] } })
    }
    this.setState({ ...this.state, loading: false })
  };

  getPhoto = async (uuid) => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/action/receipt/serve",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + JSON.parse(localStorage.getItem('state'))?.user?.token?.uuid
        },
        body: JSON.stringify({
          "receipt": {
            "uuid": uuid
          },
        })
      }
    );
    const blob = await response.blob()
    return URL.createObjectURL(blob)
  };


  render() {
    const action = this.props.action;
    let editable = false;
    if (action.status.global.ident != 'cancelled' && action.status != 'active' && action.status != 'finished') {
      editable = true
    }
    const icons = {
      'active': <ActiveIcon />,
      'editable': <NewIcon />,
      'cancelled': <CanceledIcon />,
      'finished': <EndedIcon />,
      'accepted': <ApprovedIcon />
    }

    const territory = this.props.user.user.role.uuid === "ASM" || this.props.user.user.role.uuid === "HSADM" ? <td>{action.territory != null ? action.territory.ident : ''}</td> : null

    return (
      <>
        <tr className={`action-row ${action.status.global.ident} ${this.state.photos.expanded ? "no-border-bottom" : ""}`}>
          <td className="status-cell" tabIndex="0" onBlur={() => { this.setState({ statusSelect: false }) }}>
            <div className={"status-icon"}>{icons[action.status.global.ident]}</div>
          </td>
          <td className="action-nr"><span>{this.props.nr}</span></td>
          <td className="action-name"><span>{action.name}</span></td>
          <td>{action.ident}</td>
          {territory}
          <td>{action.event != null ? action.event.name : ''}</td>
          <td>
            {action.actionPoints.map((item, key) => {
              return <p key={key}>{item.name + ', ' + item.address.streetAddress + ' ' + (item.address.streetNumber != null ? item.address.streetNumber : '') + ' ' + item.address.cityName}</p>
            })}
          </td>
          <td>{action.since != null ? moment(action.since.date).format('D/M/Y') : ''}</td>
          <td>{action.since != null && action.until != null ? moment(action.since.date).format('H:mm') + ' - ' + moment(action.until.date).format('H:mm') : ''}</td>
          <td>
            {action.users ? action.users.map((item, key) => {
              return <p key={"item-" + key}>{item.firstname + ' ' + item.lastname}</p>
            }) : null}
          </td>
          <td>
            <div className="buttons">
              <button onClick={() => { this.getReceipts(); this.setState({ ...this.state, photos: { ...this.state.photos, expanded: !this.state.photos.expanded } }) }} className="btn">
                {this.state.loading ? "Pakrovimas" : this.state.photos.expanded ? "Paslėpti kvitus" : "Rodyti kvitus"}
              </button>
            </div>
          </td>
        </tr>
        {this.state.photos.expanded && <tr className='report-photos'>
          <td colSpan={10}>
            {<div className="photos-wrapper" >

              {this.state.photos.error ? <p>Radās kļūda</p> :
                this.state.photos.list === "none" ?
                  <p>Nuotraukų nėra</p>
                  :
                  this.state.photos.list.map((element, index) => (
                    <div className='photo' key={index} >
                      <img src={element.img} alt="" onClick={() => this.props.setPhotoModal({ open: true, imgUrl: element.img })} />
                      <p>Kvitai #{index + 1} - {element.date}</p>
                    </div>
                  ))}
            </div>}
          </td>
        </tr>}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, reports, user } = state
  return { screen, reports, user }
};

export default withRouter(connect(
  mapStateToProps,
  {

  }
)(ReportItem));