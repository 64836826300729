import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import BaseComponent from "../Helpers";
import ReportItem from ".//ReportItem";
import { uploadReports, downloadReport } from '../../actions/reports';
import moment from 'moment';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { ReactComponent as CalendarIcon } from '../../../img/calendar.svg';
import { API_URL } from '../../helpers/fetch';
import Dropdown from "../Dropdown";
import ReactPaginate from 'react-paginate';
import Export from '../modals/Export';
import ExportModal from '../modals/ExportModal';
import PhotoModal from '../modals/PhotoModal';

class ReportsPage extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = {
      dropdown: false,
      exportPopup: false,
      since: moment().subtract(1, 'months'),
      until: moment(),
      photoModal: {
        open: false,
        imgUrl: ""
      }
    };
    this._bind('showExportPopup', 'hideExportPopup');
  }

  componentWillMount() {
    this.props.uploadReports(this.state.since.format('YYYY-MM-DD'), this.state.until.format('YYYY-MM-DD'))
  }

  hideExportPopup() {
    this.setState({ exportPopup: false })
  }

  showExportPopup() {
    this.setState({ exportPopup: true })
  }

  setPhotoModal = obj => {
    this.setState({...this.state, photoModal: obj})
  }

  render() {
    const sinceDate = this.state.since.toDate();
    const untilDate = this.state.until.toDate();

    const CalendarInput = ({ value, onClick }) => (
      <CalendarIcon className="icon-calendar" onClick={onClick} />
    );

    const reportsList = this.props.reports.list.filter((item, key) => {
      if (item.status.global.ident === 'finished') {
        return true;
      }
    })

    return (
      <section className="actions reports">
        <div className="page-header actions-header">
          <h1>Ataskaitos</h1>
          <a className="btn export-btn" onClick={ this.showExportPopup }>Eksportuoti</a>
          <div className="calendar-dropdown">
            <p className="selected" onClick={() => {
              this.setState({ dropdown: !this.state.dropdown })
            }}>{this.state.since.format('YYYY.MM.DD') + ' - ' + this.state.until.format('YYYY.MM.DD')}</p>
            <div className={this.state.dropdown ? "month-dropdown dates-dropdown open" : "month-dropdown dates-dropdown"}>
              <div className="since-date">
                <span>no </span>
                <label>{this.state.since.format('YYYY.MM.DD')}</label>
                <DatePicker
                  selected={sinceDate}
                  locale="lt"
                  popperPlacement="bottom-right"
                  onChange={(sinceDate) => {
                    let dt = moment(sinceDate);
                    this.setState({ since: dt })
                    this.props.uploadReports(dt.format('YYYY-MM-DD'), this.state.until.format('YYYY-MM-DD'))
                  }}
                  customInput={<CalendarInput />}
                />
              </div>
              <div className="since-date">
                <span>iki </span>
                <label>{this.state.until.format('YYYY.MM.DD')}</label>
                <DatePicker
                  selected={untilDate}
                  locale="lt"
                  popperPlacement="bottom-right"
                  onChange={(untilDate) => {
                    let dt = moment(untilDate);
                    this.setState({ until: dt })
                    this.props.uploadReports(this.state.since.format('YYYY-MM-DD'), dt.format('YYYY-MM-DD'))
                  }}
                  customInput={<CalendarInput />}
                />
              </div>
            </div>
          </div>
        </div>
        <table className="actions-table">
          <thead>
            <tr>
              <td>Statusas</td>
              <td>Nr</td>
              <td>Aktyvacijos pavadinimas</td>
              <td>Aktyvacijos ID</td>
              { this.props.user.user.role.uuid === "ASM" || this.props.user.user.role.uuid === "HSADM" ? <td>Teritorija</td> : null }
              <td>Veikla / Veiklos ID</td>
              <td>Vieta</td>
              <td>Data</td>
              <td>Laikas</td>
              <td>Komandos narys</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {reportsList.map((item, key) => {
              return (
                <ReportItem key={key} nr={key + 1} action={item} setPhotoModal={this.setPhotoModal}/>
              )
            })
            }
          </tbody>
        </table>
        <ExportModal
          open={this.state.exportPopup}
          hide={this.hideExportPopup} >
          <Export hide={this.hideExportPopup} finished={true} />
        </ExportModal>
        <PhotoModal open={this.state.photoModal.open} url={this.state.photoModal.imgUrl} setPhotoModal={this.setPhotoModal}/>
        {this.props.reports.count > 100 ?
          <ReactPaginate
            previousLabel={'Ankstesnis'}
            nextLabel={'Sekantis'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={this.props.reports.maxPage}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={(data) => { this.props.uploadReports(this.state.since.format('YYYY-MM-DD'), this.state.until.format('YYYY-MM-DD'), data.selected) }}
            containerClassName={'pagination'}
            activeClassName={'active'}
          /> : null}
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, reports, user } = state
  return { screen, reports, user }
};

export default withRouter(connect(
  mapStateToProps,
  {
    uploadReports,
    downloadReport
  }
)(ReportsPage));