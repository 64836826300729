export const loadState = () => {
	try {
		const serializedState = localStorage.getItem('state');

		if (serializedState === null) {
			return { 
				actions: {
					list: [],
					default: {
					  id: "-",
					  name: "",
					  event: { name: ""},
					  locations: [],
					  date: "",
					  timestart: 0,
					  timeend: 0,
					  personnel: [],
						queueData: []
					},
					filter: {},
					page: 0,
					preloader: false
				},
				reports: {
					list: []
				},
				personnel: {
					filter: {}
				},
				territories: []
			}
		}
		return JSON.parse(serializedState);
	} catch (err) {
		return { 
			actions: {
					list: [],
					default: {
					  id: "-",
					  name: "",
					  event: { name: ""},
					  locations: [],
					  date: "",
					  timestart: 0,
					  timeend: 0,
					  personnel: [],
						queueData: []
					},
					filter: {},
					page: 0,
					preloader: false
				},
			reports: {
				list: []
			},
			personnel: {
				filter: {}
			},
			territories: []
		}
	}
}

export const saveState = (state) => {
	try {
		const serializedState = JSON.stringify(state);
		localStorage.setItem('state', serializedState);
	} catch (err) {
		// errors
	}
}

export const clearState = () => {
	try {
		console.log(localStorage.getItem('state'))
		localStorage.removeItem('state');
		console.log(localStorage.getItem('state'))
		console.log('clear state')
	} catch (err) {
		// errors
		console.log(err)
	}
}