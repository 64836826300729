import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ReactComponent as ColorLine } from '../../img/kreski.svg'

export default function Header(props) {

  return (
    <header>
      <ColorLine className="color-line" preserveAspectRatio="none"/>
      
    </header>
  );
}
